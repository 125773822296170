.servicepx {
  padding-bottom: 10vh;
  padding-top: 10vh;
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
}
.serviceTab {
  margin-top: 10vh;
}
.heading {
  height: 100%;
  color: #112898;
}
.heading span {
  font-weight: 800;
  font-size: 1.7rem;
  text-transform: capitalize;
}
.heading label {
  height: 3px;
  width: 100px;
  background-color: red;
  margin: 4px;
  /* margin-right: 30px; */
}
.services {
  text-align: center;
  background-color: #cfe9fa;
  width: 90%;
  margin: auto;
  padding: 15px;
  margin-bottom: 80px;
  height: 180px;
  border-radius: 20px;
  font-family: garamond;
}
.services h5 {
  font-weight: 500;
}
.services p {
  font-weight: 100;
  font-size: 20px;
  margin-top: 15px;
  height: 250px;
}
.services ion-icon {
  font-size: 100px;
  margin: 15px;
  margin-top: 10px;
  /* color: #0772ba; */
  position: relative;
  margin-top: -80px;
  background-color: white;
  padding: 15px;
  border-radius: 50%;
}
.serviceBtn ion-icon {
  font-size: 40px;
  background-color: aliceblue;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (orientation: portrait) {
  .service {
    padding-top: 4vh;
  }
  .servicepx {
    padding-top: 15vh;
  }
  .heading label {
    height: 3px;
    width: 60px;
    background-color: red;
    margin: 4px;
    /* margin-right: 30px; */
  }
}
