.about {
  /* height: 60vh; */
  /* background-color: white; */
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
  padding-top: 7%;
}
.subabout {
  font-size: 24px;
  font-weight: 600;
}
.subabout span {
  /* color: black; */
  font-weight: bold;
  font-size: 30px;
}
.subabout {
  margin-bottom: 15px;
}
.about button {
  background-color: rgb(23, 154, 235);
  color: white;
  padding: 10px;
  width: 60%;
  border: none;
  margin-top: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 30px;
}
.about col {
  padding-bottom: 200px;
}
.aboutTime ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}
.aboutTime ul li {
  flex: 1;
  padding-top: 10px;
  border-bottom: 1px solid #112898;
  font-size: 18px;
  font-family: dosis;
}
.aboutTime ul li:nth-child(2) {
  text-align: right;
}
.aboutTime {
  border: 6px solid #d7e0ef;
  padding: 30px;
  width: 100%;
  border-radius: 10px;
}
.aboutTime h5 {
  font-weight: bold;
}
.abouthelp {
  padding-top: 30px;
  padding-bottom: 30px;
}
.abouthelp label {
  font-weight: bold;
}
.aboutTime button {
  background-color: #e42326;
  color: white;
  padding: 10px;
  width: 40%;
  border: none;
  margin-top: 7%;
}
.cIcons ion-icon {
  font-size: 18px;
  margin-right: 15px;
  margin-top: 15px;
  font-weight: 700;
  color: #e42326;
}
.about1 {
  font-size: 20px;
}
