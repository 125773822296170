.des1 h4 {
  font-weight: 700;
  border-right: 3px solid #112898;
  padding-right: 20px;
}
.middle {
  padding: 20px;
}
.iptap ion-icon {
  font-size: 5rem;
}
.iptap .col {
  text-align: center;
  padding: 10px;
}
.iptap h4 {
  text-transform: uppercase;
  font-size: 16px;
}
.iptap .col:nth-child(2) {
  color: #ec0002;
}
.iptap .col:nth-child(3) {
  color: #112898;
}
@media screen and (max-width: 768px) {
  .des1 h4 {
    font-weight: 800;
    border: none;
    padding-right: 20px;
    margin-top: 30px;
    text-align: center;
    font-size: 20px;
  }
  .iptap ion-icon {
    font-size: 3rem;
    margin: 20px;
  }
}
