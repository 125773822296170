.main {
  /* margin-top: 50px; */
  /* height: 80vh; */
  /* background-color: green; */
  position: relative;
  background-image: url("../../../images/hero1.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 40vh;
  filter: brightness(1.3);
}
.topBig {
  /* margin-bottom: 10vh; */
  text-align: center;
}
.topBig label {
  background-color: #e42326;
  color: white;
  width: 70%;
  font-weight: bold;
  padding: 20px;
}
.topBig h1 {
  font-size: 50px;
  font-weight: 700;
  border: 2px dotted red;
  border-radius: 50px;
  padding: 10px;
  margin-bottom: 10vh;
}
.basicdesc {
  color: #e42326;
  font-size: 12px;
  font-weight: bold;
}

.topBig h1 span {
  font-size: 50px;
  font-weight: bold;
  color: #112898;
  font-family: dosis;
  padding-left: 0;
  padding-right: 0;
}
.main img {
  position: absolute;
  top: 0vh;
  right: 10%;
  height: 100%;
  /* margin-left: 60%; */
  margin-bottom: 0;
}
.mindDiv {
  /* margin-top: 15vh; */
  /* height: 50vh; */
  width: 100%;
  /* background-color: aqua; */
  margin-left: 10%;
  padding-bottom: 20vh;
}

.mindDiv h4 {
  /* text-transform: capitalize; */
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: dosis;
}

.mindDiv h3 {
  font-size: 1.4rem;
  font-weight: 900;
  color: #fff;
}
.mindDiv p {
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
  line-height: 1;
}

.btns {
  display: flex;
  width: 100%;
  gap: 20px;
  height: 100%;
}
.mindDiv button {
  height: 50px;
  width: 100%;
  background-color: #fd0105;
  font-size: 18px;
  font-weight: 700;
  text-transform: lowercase;
  color: white;
  margin: 20px 15px;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .main {
    height: auto;
    position: relative;
    background-image: url("../../../images/hero1.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    padding-top: 10%;
    text-align: center;
    margin-top: 100px;
    filter: brightness(1);
  }
  .topBig h1 {
    font-size: 1.5rem; /* Changed to rem for better scaling */
    font-weight: bold;
    border: 1px groove #112898;
    border-radius: 40px;
    padding: 20px;
    margin-bottom: 5vh;
  }
  .topBig h1 span {
    font-size: 2rem;
    font-weight: 600;
    font-family: cormorant;
  }

  .mindDiv {
    height: auto;
    width: 100%;
    color: #fd0105;
    margin: auto;
  }

  .mindDiv h3 {
    font-size: 1.2rem;
    line-height: 1;
  }
  .mindDiv button {
    height: 50px;
    width: 100%;
    margin-top: 20px;
    background-color: #e42326;
    border: none;
    font-size: large;
    font-weight: bold;
    color: white;
    padding: 10px;
  }
  .topBig img {
    position: absolute;
    left: 0;
    width: 95%;
  }
}
