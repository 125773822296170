@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
* {
  padding: 0;
  margin: 0;
}
body {
  font-family: "Cormorant Garamond", serif;
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
  color: #112898;
  overflow-x: hidden !important;
  font-size: 15px;
}

.Rcolor {
  color: #ec0002;
  font-size: 24px;
  font-weight: 700;
}
.Rcolor1 {
  color: #112898;
  font-size: 22px;
  font-weight: 500;
}
.tweets {
  width: 50%;
  height: 500px;
  overflow: auto;
  margin: auto;
}
.fixedwhatsapp {
  position: fixed;
  height: 40px;

  bottom: 40px;
  right: 40px;
  display: none;
}
.fixedwhatsapp img {
  height: 50px;
}
.labelimages {
  display: flex;
}
.labelimages img {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;

    padding: 0;
    margin: 0;
  }
  .tweets {
    width: 100%;
    height: 500px;
    margin: 20px;
  }
}
.css-1dbjc4n {
  background-color: green;
}
