.served h4 {
  font-weight: bold;
  text-transform: capitalize;
}
.served {
  /* height: 40vh; */
  /* background: white; */
  height: 100%;
  width: 100%;
  padding-top: 40px;
}

.dataV h2 {
  font-size: 26px;
  font-weight: bold;
}
.datv {
  margin-top: 5vh;
  margin-bottom: 5vh;
  text-align: center;
}
.datv .col:nth-child(1) {
  border-left: 2px solid lightgrey;
  border-right: 2px solid lightgrey;
}
.datv .col:nth-child(2) {
  border-right: 2px solid lightgrey;
}
.datv .col:nth-child(3) {
  border-right: 2px solid lightgrey;
}
.servs {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .datv .col:nth-child(2) {
    border-left: 2px dotted lightgrey;
  }
  .datv .col:nth-child(3) {
    border-right: 1px dotted lightgrey;
  }
}
