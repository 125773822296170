.Value {
  padding-top: 10vh;
  /* padding-bottom: 10vh; */
  /* height: 50vh; */
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
  width: 100%;
  height: 100%;
}
.para1 {
  font-size: 20px;
  font-weight: 400;
  margin: 20px auto;
}
.valuetab {
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
}

.valuetab ul {
  margin-top: 0%;
  padding-top: 10%;
  font-weight: bold;
}

.valuetab ul li {
  /* margin-top: 10%; */
  padding-top: 25px;
  /* font-weight: bold; */
}
.fade-left {
  margin: 10px;
}
