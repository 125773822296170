.service {
  padding-top: 30px;
  height: auto;
  width: 100vw;
  height: 100%;
  /* background-color: bisque; */
}
.service p {
  font-size: 20px;
}
.data h5 {
  font-weight: bold;
}
.data h5 {
  font-weight: bold;
}
.data .col div {
  width: 93%;
  /* background-color: green; */
  margin: auto;
  text-align: center;
  padding: 20px;
  height: 95%;
  box-shadow: -4px 1px 17px 0px rgba(216, 235, 248, 0.75);
  -webkit-box-shadow: -4px 1px 17px 0px rgba(245, 243, 243, 0.75);
  -moz-box-shadow: -4px 1px 17px 0px rgba(245, 243, 243, 0.75);
  box-shadow: -3px 2px 35px 2px rgba(168, 222, 224, 0.75);
  -webkit-box-shadow: -3px 2px 35px 2px rgba(168, 222, 224, 0.75);
  -moz-box-shadow: -3px 2px 35px 2px rgba(168, 222, 224, 0.75);
}
.fliptext {
  font-weight: 900;
  text-transform: uppercase;
}
.col-3 h3 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  font-size: 65px;
  margin-left: 40px;
  margin-top: 15vh;
  opacity: 0.08;
}
.data p {
  display: none;
}

@media screen and (max-width: 768px) {
  .service {
    padding-top: 30px;
    height: auto;
  }
  .fliptext {
    position: absolute;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 800px;
    /* background-color: aqua; */
    /* left: 0; */
    left: -150px;
  }
}
