.panel {
  width: 40%;
  height: 30vh;
  margin: auto;
  text-align: center;
  line-break: 10px;
  margin-top: 20vh;
}
.panel input {
  width: 40%;
  height: 50px;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid lightgrey;
}
.panel button {
  width: 30%;
  height: 50px;
  margin-bottom: 20px;
  text-align: center;
  margin: 5px;
}
.panel h6 {
  text-decoration: underline;
}
.tbtab button {
  margin-right: 3px;
}
.tbtab button {
  width: 35%;
  margin: 3px;
}
.tbtab input {
  height: 40px;
}
.boxdd button:nth-child(1) {
  background-color: green;
}
.boxdd button:nth-child(2) {
  background-color: brown;
}
.boxdd button:nth-child(3) {
  background-color: rgb(117, 125, 12);
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .panel {
    width: 80%;
    height: 30vh;
    margin: auto;
    text-align: center;
    line-break: 10px;
    margin-top: 20vh;
  }
  .panel {
    width: 70%;
    height: 30vh;
    margin: auto;
    text-align: center;
    line-break: 10px;
    margin-top: 20vh;
  }
  .panel input {
    width: 70%;
    height: 50px;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid lightgrey;
  }
  .panel {
    width: 80%;
    height: 30vh;
    margin: auto;
    text-align: center;
    line-break: 10px;
    margin-top: 20vh;
  }
  .panel button {
     width: 70%;
  }
}
