.activities .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
}

.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}
.im {
  position: relative;
}
.float {
  position: absolute;
  font-size: 20px;
  color: white;
  background-color: brown;
  width: 100%;
  margin-top: -40%;
  text-align: center;
}
@media (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}
