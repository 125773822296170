.partner {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.partner h4 {
  font-weight: bold;
  color: #222;
  font-weight: 900;
  font-size: 2rem;
}

.partn {
  font-size: 20px;
  color: #333;
}

.slick-slider {
  margin: 20px 0;
}

.slick-slide img {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0 auto;
}

.slider-item {
  padding: 0 10px;
  position: relative;
}

.text-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5f7;
  width: 200px;
  height: 200px;
}

.text-contents {
  font-size: 35px;
  color: #555;
  font-weight: 800;
  text-align: center;
}

.slick-prev,
.slick-next {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
  .partner {
    padding: 20px;
  }
  .partner img {
    width: 200px;
    height: 200px;
    display: block;
  }
}
