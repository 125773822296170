.bg-footer {
  background-color: #33383c;
  padding: 50px 0 30px;
  width: 100%;
  height: 100%;
}
.footer-heading {
  letter-spacing: 2px;
}
a {
  text-decoration: none;
}

.footer-link a {
  color: #acacac;
  line-height: 40px;
  font-size: 18px;
  transition: all 0.5s;
}

.footer-link a:hover {
  color: #1bbc9b;
}

.contact-info {
  color: #acacac;
  font-size: 18px;
}

.footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.google {
  background-color: #d6492f;
  color: #ffffff;
}

.apple {
  background-color: #424041;
  color: #ffffff;
}

.footer-alt {
  color: #ffffff;
  font-size: 20px;
  font-weight: 650;
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.footer-heading:after {
  content: "";
  width: 25px;
  border-bottom: 1px solid #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #1bbc9b;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 500px;
  position: absolute;
  top: 60%;
  left: 60%;
  transform: translate(-60%, -60%);
  padding: 2em;
  background: white;
  border: 1px solid lightgrey;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}
.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}
/* Demo Styles */
html,
body {
  height: 100%;
}
html {
  font-size: 18px;
  line-height: 1.4;
}

.modal-window > div {
  border-radius: 1rem;
}
.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}
/* .logo {
  max-width: 150px;
  display: block;
} */
small {
  color: lightgray;
}
@media screen and (max-width: 768px) {
  .floatingdiv {
    position: fixed;
    display: block;
  }
  .modal-window > div {
    font-size: 10px;
    width: 80%;
    left: 48%;
    top: 40%;
  }
}
