.book {
  height: 50px;
  background-color: #e42326;
  color: white;
  border: none;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
}
.mmivc {
  z-index: 100;
}
.first {
  padding-top: 10px;
  height: 50px;
  color: #112898;
  font-weight: 600;
}
.first ion-icon {
  position: absolute;
  font-size: 25px;
  /* margin-right: 15px; */
  /* margin-top: 10px; */
  margin-left: -35px;
  color: #112898;
  font-weight: bold;
}
.navtabbg {
  background: linear-gradient(135deg, #e5e5f7 0%, #e6f4f7 50%, #d7e0ef 100%);
  height: 100px;
}
.navtab {
  /* position: fixed; */

  left: 0;
  right: 0;
  /* top: 0; */
  font-family: "Montserrat", "sans-serif";
  height: 100px;
  padding: 0 5%;
  /* padding-top: 5%; */
}
.socialIcons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialIcons ion-icon {
  font-size: 20px;
  margin: 5px;
}
.navtab .logo {
  float: left;
  width: 40%;
  width: 170px;
  padding-left: 5px;
  background-color: none;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #112898;
  margin-top: -25px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  display: flex;
}
.wimg {
  height: 70px;
  margin-left: 5px;
  margin-top: -20px;
}
.logo .limg {
  height: 165px;
  width: 160px;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
  background-color: white;
  /* background-color: green; */
  /* box-shadow: -3px 2px 43px 0px rgba(111, 167, 202, 10.99); */
  box-shadow: -3px 2px 43px 12px rgba(57, 103, 149, 0.59);
}
.slogan {
  /* position: relative; */
  /* z-index: ; */
  color: white;
  background-color: #e42326;
  /* text-align: center; */
  font-weight: 500;
  width: 100vw;
  font-size: 18px;
  left: 0;
  right: 0;
}
/* .nav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.nav button {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.nav button:hover {
  background-color: #ddd;
} */

.navtab .links {
  float: right;
  padding: 0;
  margin: 0;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navtab .links li {
  list-style: none;
}
.navtab .links a {
  display: block;
  padding: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #112898;
  text-decoration: none;
  position: relative;
}
.navtab .links a:hover {
  color: #e42326;
}
/* .navtab .links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #e42326;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.navtab .links a:hover::before {
  visibility: visible;
  transform: scaleX(1);
  color: #e42326;
} */
#navtab-toggle {
  position: absolute;
  top: -100px;
}
.navtab .icon-burger {
  display: none;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
.navtab .icon-burger .line {
  width: 30px;
  height: 5px;
  background-color: #112898;
  margin: 5px;
  border-radius: 3px;
  transition: all 0.5s ease-in-out;
}
.mmivc {
  position: relative;
}
.smal {
  background-color: #e6f4f7;
  padding: 4px;
}
.floatingdiv {
  display: none;
}
.donatebtn {
  position: fixed;
  margin-top: 2.5%;
  margin-left: -3%;
  /* left: 0; */
}
.rcpdonate ul {
  display: flex;
  list-style: none;
}
.rcpdonate ul li {
  flex: 1;
}
.rcpdonate img {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .floatingdiv {
    position: fixed;
    display: block;
  }
  .modal-window > div {
    font-size: 10px;
    width: 600px;
  }
  .mmivc {
    position: absolute;
    background-color: #e42326;
  }
  .navtab .logo {
    float: none;
    width: auto;
    justify-content: center;
    position: fixed;
  }

  .slogan {
    position: relative;
    /* z-index:1 ; */
    color: white;
    background-color: #e42326;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 100vw;
    left: 0;
    right: 0;
  }
  .navtabbg {
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    height: 100px;
    z-index: 100;
    width: 100vw;
  }

  .navtab .links {
    width: 100%;
    /* float: none; */
    position: fixed;
    /* z-index: 9; */
    left: 0;
    right: 0;
    top: 130px;
    bottom: 100%;
    width: 70%;
    height: auto;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: rgba(229, 234, 236, 0.9);
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    text-align: center;
    z-index: -1;
  }

  .hidesmall {
    /* font-size: 10px; */
    display: none;
  }
  .navtab .links a {
    font-size: 20px;
    color: #112898;
  }
  .navtab :checked ~ .links {
    bottom: 0;
    width: 100%;
  }
  .navtab .icon-burger {
    display: block;
    /* position: fixed; */
    margin: 0;
    padding: 0;
    /* top: 30vh; */
    /* right: 10%; */
    top: 6vh;

    /* top: 0px; */
  }
  .navtab :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }
  .navtab :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }
  .navtab :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }
  .wimg {
    height: 50px;
    margin-left: 5px;
    margin-top: 20px;
  }
  .logo .limg {
    /* position: absolute; */
    height: 80px;
    width: 80px;
    margin-top: 40px;
    margin-left: -30px;

    /* justify-content: center; */
    border-radius: 50%;
    padding: 5px;
    background-color: white;
    /* background-color: green; */
    /* box-shadow: -3px 2px 43px 0px rgba(111, 167, 202, 10.99); */
    box-shadow: -3px 2px 43px 12px rgba(57, 103, 149, 0.59);
  }
  .navtab .links {
    display: block;
  }
  .navtab .links a:hover {
    text-decoration: none;
    border: none;
  }
}
